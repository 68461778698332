import React, { ChangeEvent, useState, FormEvent } from "react"
import { graphql } from "gatsby"
import "./login.module.scss"

type AuthenticatedPortalLink = {
  success: boolean
  loginLink: string
  clientMessage: string
}

export const LoginForm = () => {
  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")
  const loginMessage = "Your Username / Password is incorrect"
  const windowAvailable = typeof window !== "undefined"

  /* eslint-disable */
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    // @ts-ignore: PestportalsClient was delayed imported
    let auth = PestportalsClient.getAuthenticatedPortalLink(user, password)
    auth.then((response: AuthenticatedPortalLink) => {
      if (response.success) {
        document.location.href = response.loginLink
      } else {
        windowAvailable && window.location.replace("/login?error=invalid")
      }
    })
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.name === "acc_num"
      ? setUser(e.target.value)
      : setPassword(e.target.value)
  }

  return (
    <section className="login-form-container alternate-background-color">
      <div className="fr-container login-form">
        <div className="input-container">
          <div className="input-field__wrapper">
            <p className="login-form__input-label">Account Number:</p>
            <label className="login-form__input-container login-form__input-container--account">
              <input
                className="input"
                type="text"
                name="acc_num"
                value={user}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="input-field__wrapper">
            <p className="login-form__input-label">Password:</p>
            <label className="login-form__input-container">
              <input
                className="input"
                type="password"
                name="pass"
                value={password}
                onChange={handleChange}
              />
            </label>
          </div>
          <button
            role="button"
            className="button--solid-secondary login-form__btn"
            onClick={handleSubmit}
          >
            Login
          </button>
        </div>
        {loginMessage && (
          <span className="login-form__error-message">
            <p>
              {windowAvailable &&
                window.location.href.includes("?error=invalid") &&
                loginMessage}
            </p>
          </span>
        )}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment LoginBannerFragment on kontent_item_hero_banner {
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
        links {
          link_id
          url_slug
        }
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
    }
  }
`
