import React, { useCallback, useEffect, useState } from "react"
import { graphql, PageProps, useStaticQuery } from "gatsby"
import { Layout } from "components/layout"
import { SEO } from "components/seo/seo"
import { GlobalFooter } from "components/global-footer/GlobalFooter"
import { ContentBlocks } from "components/content-blocks/ContentBlocks"
import { LoginForm } from "components/login/LoginForm"
import { GenericHero } from "components/hero/GenericHero/GenericHero"
import { appendScript } from "assets/helper/appendScript"

type PageContext = PageProps["pageContext"] & {
  slug: string
  breadcrumb: {
    crumbs: Record<string, string>[]
    location: string
  }
  linkedPagesUrlPathCodex: Record<string, string>
}

interface LoginPageProps extends PageProps {
  pageContext: PageContext
}

const LoginPage: React.FC<LoginPageProps> = ({ location, pageContext }) => {
  const { pathname, hash } = location
  const data = useStaticQuery(GetLoginData)
  const [page] = data.allKontentItemPage.nodes
  const {
    banner,
    global_footer,
    rich_content,
    seo_metadata__meta_title,
    seo_metadata__meta_description,
  } = page.elements
  const { slug, linkedPagesUrlPathCodex } = pageContext

  const [subDomain, setSubDomain] = useState("")
  const [isRender, setIsRender] = useState(false)

  const script = useCallback(() => {
    !(window as any).jQuery &&
      appendScript({
        id: "jquery-3.6.0.min.js",
        scriptToAppend: "https://code.jquery.com/jquery-3.6.0.min.js",
        isAsync: false,
      })
    // @ts-ignore: PestportalsClient was delayed imported
    if ((window as any).jQuery && typeof PestportalsClient !== "object")
      appendScript({
        id: "pestportalsClient.js",
        scriptToAppend:
          "https://lmk.pestroutes.com/resources/js/lobster/pestportalsClient.js",
        isAsync: true,
      })
    else setTimeout(script, 200)
  }, [])

  useEffect(() => {
    if (isRender) {
      script()
      fetch("https://lmk.pestroutes.com/lobster/getConnectedOffices")
        .then(response => response.json())
        .then(r => {
          setSubDomain(Object.keys(r.connectedOffices.dbOffices)[0])
        })
    } else {
      setIsRender(true)
    }
  }, [isRender])

  const subHeading = `<p>Don’t know your password? <br/> <a href='https://${subDomain}.pestportals.com/forgotPassword' target='_blank' rel='noopener noreferrer' class='password-recovery-link'> Get your login info</a></p>`
  const bannerWithHardCodedRequestPassword = {
    ...banner.value[0].elements,
    sub_heading: {
      value: subHeading,
    },
  }

  return (
    <Layout
      location={slug}
      pathName={pathname}
      hash={hash}
      linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
    >
      <SEO
        title={seo_metadata__meta_title?.value}
        description={seo_metadata__meta_description?.value}
        noIndex={true}>
      </SEO>

      <GenericHero banner={bannerWithHardCodedRequestPassword} />

      <LoginForm />

      <ContentBlocks content={rich_content.modular_content} />

      <GlobalFooter content={global_footer} meta={pageContext} />
    </Layout>
  )
}

const GetLoginData = graphql`
  query GetLoginData {
    allKontentItemPage(
      filter: { elements: { slug: { value: { eq: "login" } } } }
    ) {
      nodes {
        elements {
          banner {
            value {
              ...LoginBannerFragment
            }
          }
          seo_metadata__meta_description {
            value
          }
          seo_metadata__meta_title {
            value
          }
          rich_content {
            ...ContentBlocksParserFragment
          }
          global_footer {
            value {
              ...GlobalFooterFragment
            }
          }
        }
      }
    }
  }
`

export default LoginPage
